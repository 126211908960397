import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`software`, `engineer`]} />
    <p>
        <div>
            <b><u>WILL DEUSCHLE</u></b>
        </div>
        <div>
            Working on kubernetes at Palantir, 2019-present
        </div>
    </p>
    <p>
        <div>
            <b>PREVIOUSLY</b>
        </div>
        <div>
            B.A. in Computer Science, Harvard 2019
        </div>
        <div>
            Software Engineer, <a href='https://www.quorum.us' target='_blank' rel='noopener noreferrer'>Quorum Analytics</a> 2016-18
        </div>
    </p>
    <p>
        <div>
            <b>SOME PROJECTS</b>
        </div>
        <div>
            Harvard's undergrad <a href='https://github.com/harvard-ml-courses/cs181-textbook' target='_blank' rel='noopener noreferrer'>machine learning textbook</a>
        </div>
        <div>
            Rusty <a href='https://github.com/willdeuschle/ry' target='_blank' rel='noopener noreferrer'>yaml searching</a>
        </div>
        <div>
            Cilium <a href='https://github.com/cilium/cilium' target='_blank' rel='noopener noreferrer'>contributions</a>
        </div>
        <div>
            Implementing a <Link to='/container-manager-in-rust'>container manager in rust</Link>
        </div>
        <div>
            Adaptive neural network architecture <a href='https://drive.google.com/file/d/18UYfVr5f_C9n4nf8B7AWf19bxh1WxQak/view' target='_blank' rel='noopener noreferrer'>research</a>
        </div>
        <div>
            Subscriptions for GraphQL <a href='https://github.com/willdeuschle/Python-GraphQL-Subscriptions' target='_blank' rel='noopener noreferrer'>apps</a>
        </div>
    </p>
    <p>
        <div>
            <b>LINKS</b>
        </div>
        <div>
            <a href='https://github.com/willdeuschle' target='_blank' rel='noopener noreferrer'>github</a>
        </div>
        <div>
            <a href='https://www.linkedin.com/in/william-deuschle-34954899/' target='_blank' rel='noopener noreferrer'>linkedin</a>
        </div>
        <div>
            <a href='https://drive.google.com/file/d/1tNgK7dpo3j-shY59h5OPoaWBF6L5Uvnp/view' target='_blank' rel='noopener noreferrer'>cv</a>
        </div>
    </p>
  </Layout>
)

export default IndexPage
